<template>
  <div>
    <b-card
      id="pdfGenerateDiv"
      ref="pdfGenerateDivRef"
      no-body
      class="invoice-preview-card p-1 main-table"
    >
      <div>
        <div
          class="card__inner"
        >
          <table style="width: 100%; page-break-before: avoid; page-break-after: auto; border-collapse: separate; border-spacing: 10px 5px;">
            <thead>
              <tr>
                <td>
                  <!-- Header -->
                  <b-card-body
                    id="main-header"
                    class="invoice-padding pb-0"
                  >
                    <div
                      class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
                    >
                      <div class="logo-wrapper">
                        <img
                          style="width:130px;"
                          src="/nirvana-memorial-garden-logo.png"
                          alt="Logo"
                        >
                      </div>
                      <div
                        style="min-width: fit-content; width: fit-content;"
                        class="px-2"
                      >
                        <h4 style="text-align: center; text-transform: uppercase; color: #000000; font-weight: 900; font-size: 12px; line-height: 18px;">
                          Approved Vendor Evaluation Form
                        </h4>
                      </div>
                      <div class="mt-md-0 mt-2 text-right">
                        <h4
                          class="invoice-title"
                          style="font-size: 18px;line-height: 21px;color: #104D9D; margin:0;"
                        >
                          {{ aveForm.stringID }}
                        </h4>
                        <div class="invoice-date-wrapper">
                          <p
                            class="invoice-date"
                            style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                          >
                            <span class="semi-bold">Evaluated By </span> {{ aveForm.createdBy ? aveForm.createdBy.name : '' }}, {{ aveForm.createdBy ? aveForm.createdBy.position : '' }}, {{ aveForm.createdBy ? (aveForm.createdBy.department.length ? aveForm.createdBy.department[0].name : '') : '' }}
                          </p>
                        </div>
                        <div class="invoice-date-wrapper">
                          <p
                            class="invoice-date"
                            style="font-weight: normal !important;font-size: 11px;line-height: 16px; margin:0;"
                          >
                            <span class="semi-bold">Created On:</span> {{ dateFormatWithTime(new Date()) }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- Spacer -->
                  </b-card-body>
                  <hr
                    class="invoice-spacing"
                    style="margin-top:25px!important"
                  >
                  <b-card-body
                    class="invoice-padding pb-0"
                  >
                    <b-row id="section1">
                      <b-col
                        cols="md-12"
                        class="p-0"
                      >
                        <b-table-simple
                          borderless
                          responsive
                        >
                          <b-tbody>
                            <b-tr>
                              <b-th
                                width="230px"
                                class="ve-th"
                              >
                                Company
                              </b-th>
                              <b-td class="ve-td">
                                {{ entityString }}
                              </b-td>
                            </b-tr>
                            <b-tr>
                              <b-th
                                width="230px"
                                class="ve-th"
                              >
                                No. of Vendors
                              </b-th>
                              <b-td class="ve-td">
                                {{ aveForm.vendorCount }}
                              </b-td>
                            </b-tr>
                            <b-tr>
                              <b-th
                                width="230px"
                                class="ve-th"
                              >
                                Period of Evaluation
                              </b-th>
                              <b-td class="ve-td">
                                {{ aveForm.period }}
                              </b-td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <!-- Invoice Client & Payment Details -->
                  <b-card-body
                    class="invoice-padding pt-2 main__body po__create preview_order_pdf"
                  >
                    <b-row
                      v-if="evaluationItems.length"
                      id="blue-button"
                    >
                      <b-col
                        cols="12"
                        class="item__description_table"
                      >
                        <!-- Invoice Description: Table -->
                        <b-table-simple
                          ref="teamsTable"
                          class="position-relative has_padding"
                          responsive
                          caption-top
                        >
                          <b-thead>
                            <b-tr class="group__tag">
                              <th>No</th>
                              <th>vendor</th>
                              <th style="min-width:500px">
                                <b-row>
                                  <b-col
                                    cols="12"
                                    md="5"
                                  >
                                    <span>item desciption</span>
                                  </b-col>
                                  <b-col
                                    cols="12"
                                    md="2"
                                  >
                                    <span>Unit</span>
                                  </b-col>
                                  <b-col
                                    cols="12"
                                    md="2"
                                  >
                                    <span>Unit Price (S$)</span>
                                  </b-col>
                                  <b-col
                                    cols="12"
                                    md="3"
                                  >
                                    <span>Adj Unit<br>price</span>
                                  </b-col>
                                </b-row>
                              </th>
                              <th>credit term (days)</th>
                              <th>late delivery (days)</th>
                              <th>late delivery (times)</th>
                              <th>no. of ncf</th>
                              <th>ADJ Unit<br>price</th>
                              <th>credit term</th>
                              <th>delivery / services</th>
                              <th>Quality</th>
                              <th>Total points</th>
                              <th>ranking</th>
                              <th>justification / remarks</th>
                            </b-tr>
                          </b-thead>
                          <b-tbody
                            class="white-body-background"
                          >
                            <tr
                              v-for="(item, index) in evaluationItems"
                              :key="index"
                              style="page-break-inside: avoid; page-break-after: auto;"
                            >
                              <td style="page-break-inside: avoid; page-break-after: auto;">
                                {{ index + 1 }}
                              </td>
                              <td
                                style="page-break-inside: avoid; page-break-after: auto;"
                              >
                                <span class="text-bold-black">{{ item.vendorName }}</span>
                              </td>
                              <b-td>
                                <b-row
                                  v-for="(pitem, itemIndex) in item.items"
                                  :key="itemIndex"
                                  class="mb-50"
                                >
                                  <b-col
                                    cols="12"
                                    md="5"
                                  >
                                    <span class="">{{ pitem }}</span>
                                  </b-col>
                                  <b-col
                                    cols="12"
                                    md="2"
                                  >
                                    <span class="">{{ item.units[itemIndex] }}</span>
                                  </b-col>
                                  <b-col
                                    cols="12"
                                    md="2"
                                  >
                                    <span class="">{{ numberFormat(item.unitCosts[itemIndex]) }}</span>
                                  </b-col>
                                  <b-col
                                    cols="12"
                                    md="3"
                                  >
                                    <span class="">{{ item.adjustedUnitPrices[itemIndex] }}</span>
                                  </b-col>
                                </b-row>
                              </b-td>
                              <b-td>
                                {{ item.creditTermDays }}
                              </b-td>
                              <b-td>
                                {{ item.lateDeliveryDays }}
                              </b-td>
                              <b-td>
                                {{ item.lateDeliveryTimes }}
                              </b-td>
                              <b-td>
                                {{ item.numberOfNCF }}
                              </b-td>
                              <b-td>
                                {{ item.numberOfAdjustedUnitPrice }}
                              </b-td>
                              <b-td>
                                {{ item.numberOfCreditTerm }}
                              </b-td>
                              <b-td>
                                {{ item.numberOfDelivery }}
                              </b-td>
                              <b-td>
                                {{ item.quantity }}
                              </b-td>
                              <b-td>
                                {{ item.totalPoint }}
                              </b-td>
                              <b-td>
                                {{ item.ranking }}
                              </b-td>
                              <b-td>
                                {{ item.remarks }}
                              </b-td>
                            </tr>
                          </b-tbody>
                        </b-table-simple>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </td>
              </tr>
              <tr
                style="
                    width: 100%; page-break-before: auto;
                    page-break-after: auto;
                    page-break-inside: avoid;
                  "
                class="footer-class-a"
              >
                <td>

                  <div>
                    <div :style="{ height: gapHeight+'mm' }" />
                    <!-- Spacer -->
                    <hr class="invoice-spacing">

                    <!-- Invoice Description: Total -->
                    <b-card-body
                      id="footer-div"
                      class="invoice-padding pb-0 footer__area"
                    >
                      <b-row class="invoice-spacing">
                        <b-col
                          cols="md-3"
                          class="p-0"
                        >
                          <p
                            style="font-size:10px; line-height:14px; margin:0 0 15px; color:#000000;"
                          >
                            <span class="bold-text">1</span> - Good <span class="bold-text">2</span> - Acceptable <span class="bold-text">3</span> - Non-Acceptable
                          </p>
                          <p
                            style="font-size:10px; line-height:14px; margin:0 0 15px; color:#000000;"
                          >
                            Total Points Acceptable: 8 Points
                          </p>
                        </b-col>
                        <b-col
                          cols="md-9"
                          class="p-0"
                        >
                          <p
                            style="font-size:10px; line-height:14px; margin:0 0 15px; color:#000000;"
                          >
                            <span class="bold-text">Adj. Unit Price:</span> By Comparison of price and term of payment
                            <span class="bold-text">Delivery/Service:</span> Delayed by 1 to 2 days considered acceptable, delayed by 1 to 2 times (each time from 3 to 5 days) withing 6 months considered non-acceptable.
                          </p>
                          <p
                            style="font-size:10px; line-height:14px; margin:0 0 15px; color:#000000;"
                          >
                            <span class="bold-text">Credit Term:</span> 30 - 45 days considered acceptable, above 45 days considered good.
                            <span class="bold-text">Quality:</span> 1 to 2 non-conformity within 3 months considered acceptable.
                          </p>
                        </b-col>
                      </b-row>
                      <b-row>

                        <!-- Col: Sales Persion -->
                        <b-col
                          v-for="(signatory, index) of signatoryAssigns"
                          :key="index"
                          cols="3"
                          md="3"
                          class="mt-md-1 mt-1"
                          order="2"
                          order-md="1"
                        >
                          <b-card-text class="mb-0">
                            <span class="font-weight-bold">{{ signatory.code }}</span>
                            <div class="sign-box">
                              <p>Pending Signature</p>
                            </div>
                            <p class="ml-75 semi-bold">
                              {{ signatory.name }}
                            </p>
                            <p class="ml-75">
                              {{ signatory.department }}
                            </p>
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </b-card-body>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BCardText, BTableSimple, BTbody, BThead,
  BTr, BTh, BTd, BCardBody,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BTableSimple,
    BTbody,
    BThead,
    BTr,
    BTh,
    BTd,
    BCardBody,
  },
  data() {
    return {
      generateNow: false,
      elHeight: 0,
      gapHeight: 1,
      purchaseRequest: {},
      pOrder: {},
      poItems: [],
      loas: [
        { title: '1. Prepared by', code: 'Prepared by' },
        { title: '2. Confirmed by', code: 'Confirmed by' },
        { title: '3. Approved by', code: 'Approved by' },
      ],
      evaluationItems: JSON.parse(localStorage.getItem('veData')),
      aveForm: JSON.parse(localStorage.getItem('aveForm')),
      signatoryAssigns: JSON.parse(localStorage.getItem('signatoryAssigns')),
      userOptions: JSON.parse(localStorage.getItem('userOptions')),
      entityString: localStorage.getItem('entityString'),
    }
  },
  // mounted() {
  //   this.$http.get('front-end/events/upcoming-events')
  //     .then(() => {
  //       this.evaluationItems = JSON.parse(localStorage.getItem('veData'))
  //       this.aveForm = JSON.parse(localStorage.getItem('aveForm'))
  //       this.signatoryAssigns = JSON.parse(localStorage.getItem('signatoryAssigns'))
  //       this.userOptions = JSON.parse(localStorage.getItem('userOptions'))
  //       this.entityString = localStorage.getItem('entityString')
  //       this.generateNow = true
  //     })
  //     .catch(() => {})
  // },
  methods: {
    resolveSignatoryName(id) {
      const user = this.userOptions.find(o => o._id === id)
      if (user) {
        return user.name
      }

      return ''
    },
    resolveSignatoryDepartment(id) {
      const user = this.userOptions.find(o => o._id === id)
      if (user) {
        return user.department.length ? user.department[0].name : ''
      }

      return ''
    },
  },
}
</script>

    <style lang="scss" scoped>
    .per-page-selector {
      width: 90px;
    }
    </style>

    <style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    </style>
    <style lang="scss">
      body div#app.semi-dark-layout {
          background: #fff !important;
          background-repeat: no-repeat !important;
          background-position: center bottom !important;
          background-size: 100% auto !important;
      }
      body {
          background: #fff !important;
      }
      .card {
          box-shadow: none !important;
      }
    </style>
    <style>
      @media print {
          html, body {
              height: 99%;
          }
          @page {
            size: A4 landscape;
          }
      }
    </style>
